import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField, convertToPriceFormat, convertToPriceFormatWithoutCents, formatNegativePrice } from 'utils/formatters'
import { ISyndicatorReportListItem, SyndicatorDealsSortingFields } from 'typescript/interfaces/syndicators'
import { Box, IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'

export const TableSyndicatorReport = TableComponent as TableComponentType<ISyndicatorReportListItem>

export const arrayData = Array(20)
  .fill({
    merchant: 'MERCHANT',
    deal: 4900,
    dealEndDate: '08.30.2020',
    participation: 50,
    dealAdvance: 500,
    syndicated: 281800,
    projPayback: 479130,
    toDatePayback: 100,
    paidOff: 100,
  })
  .map((c, i) => ({
    ...c,
    id: i,
    merchant: `${c.merchant} ${i}`,
  }))

interface IUseSyndicatedDealsParams {
  handleExpand: (dealId: number) => void
}

export const useSyndicatorReport = (params?: IUseSyndicatedDealsParams) => {
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<ISyndicatorReportListItem>>>([
    {
      label: 'Merchant',
      field: SyndicatorDealsSortingFields.merchant,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.merchant),
    },
    {
      label: 'Deal',
      field: SyndicatorDealsSortingFields.deal,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.deal),
    },
    {
      label: 'Start Date',
      field: SyndicatorDealsSortingFields.dealStartDate,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.startDate),
    },
    {
      label: 'Deal End Date',
      field: SyndicatorDealsSortingFields.dealEndDate,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.dealEndDate),
    },
    {
      label: 'Status',
      field: 'status',
      render: (data) => convertEmptyField(data.status),
    },
    {
      label: 'Deal Advance',
      field: SyndicatorDealsSortingFields.dealAdvance,
      bodyCellClassName: classes.accentCell,
      sorting: true,
      render: (data) => convertToPriceFormat(data.dealAdvance),
    },
    {
      label: 'Proj Payback',
      field: SyndicatorDealsSortingFields.projPayback,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertToPriceFormat(data.projPayback),
    },
    {
      label: 'Total to Payback',
      field: SyndicatorDealsSortingFields.toDatePayback,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertToPriceFormat(data.toDatePayback),
    },
    {
      label: 'Participation',
      field: SyndicatorDealsSortingFields.participation,
      bodyCellClassName: classes.accentCell,
      render: (data) => `${convertEmptyField(data.participation)} %`,
    },
    {
      label: 'Syndicated',
      field: SyndicatorDealsSortingFields.syndicated,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.syndicated),
    },
    {
      label: 'Collected',
      field: 'collected',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.collected),
    },
    {
      label: 'Remaining Balance',
      field: SyndicatorDealsSortingFields.syndicatorRemaining,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.remainingBalance >= 0 ? data.remainingBalance : 0),
    },
    {
      label: 'PNL',
      field: 'pnl',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Tooltip
          placement="bottom-end"
          title={
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Funding</Typography>
                <Box ml="1rem">-{convertToPriceFormat(data.syndicated)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Commission</Typography>
                <Box ml="1rem">-{convertToPriceFormat(data.totalCommission)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Legal Fee</Typography>
                <Box ml="1rem">-{convertToPriceFormat(data.legalFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Mgm Fee</Typography>
                <Box ml="1rem">-{convertToPriceFormat(data.mgmFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Default Fee</Typography>
                <Box ml="1rem">-{convertToPriceFormat(data.defaultFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Bank Fee</Typography>
                <Box ml="1rem">+{convertToPriceFormat(data.bankFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Collected</Typography>
                <Box ml="1rem">+{convertToPriceFormat(data.collected)}</Box>
              </Box>
            </Box>
          }
        >
          <Box>{formatNegativePrice(data.pnl, convertToPriceFormatWithoutCents)}</Box>
        </Tooltip>
      ),
    },
    ...(params != null
      ? [
          {
            label: '',
            field: 'status',
            render: (data: ISyndicatorReportListItem) => (
              <IconButton onClick={() => params.handleExpand(data.deal)}>
                <SvgIcon
                  style={{ transform: `rotate(${data.tableOnlyRowExpanded ? '180deg' : '0'})` }}
                  fontSize="inherit"
                  viewBox="0 0 20 20"
                  component={CircleArrowIcon}
                />
              </IconButton>
            ),
          },
        ]
      : []),
  ])

  return {
    columns: columns.current,
  }
}
